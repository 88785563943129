<template>
  <div v-if="watchOrdersToDisplay.length">
    <FormEntry class="bg-warning_bg border-2 rounded !border-warning p-2 space-y-2">
      <div class="text-warning">
        {{ $tc('order.watch_orders_existing', undefined, { count: watchOrdersToDisplay.length }) }}
        <button
          class="ml-3 p-2 rounded bg-warning text-white font-600"
          data-test="show-orders"
          @click="isOpenWatchOrdersModel = true"
        >
          {{ $t('show') }}
        </button>
      </div>
    </FormEntry>
    <Modal
      :is-open="isOpenWatchOrdersModel"
      :title="$tc('order.currently_existing')"
      :max-width="600"
      data-test-id="watch-orders"
      view-only
      @cancel="isOpenWatchOrdersModel = false"
    >
      <DetailsListEntry
        v-for="order in watchOrdersToDisplay"
        :key="order._id"
        :title="order.title"
        :content="order.text"
        :data-test="order.text"
        class="not-first:border-t-1"
      />
    </Modal>
  </div>
</template>

<script lang="ts">
import { store as coreStore, DetailsListEntry, i18n } from '@anschuetz-elog/frontend-core';
import { orderBy } from 'lodash';
import moment from 'moment';
import { computed, defineComponent, ref } from 'vue';

import FormEntry from '#/components/FormEntry.vue';
import Modal from '#/components/Modal.vue';
import useFind from '#/compositions/useFind';
import { dateTimeStr, getUserFullName } from '#/utilities';

export default defineComponent({
  name: 'WatchOrders',
  components: { FormEntry, Modal, DetailsListEntry },
  setup() {
    const { data: watchOrders } = useFind('order');
    const isOpenWatchOrdersModel = ref<boolean>(false);

    const userId = computed(() => {
      return coreStore.getters.auth.userId;
    });
    const isAuthenticated = computed(() => {
      return coreStore.getters.auth.isAuthenticated;
    });

    const userWatchOrders = computed(() => {
      const ordersFilteredByUser = watchOrders.value.filter((order) =>
        order.relevantFor.some((user) => user._id === userId.value),
      );
      if (!ordersFilteredByUser.length) {
        return [];
      }
      return orderBy(ordersFilteredByUser, (order) => moment(order.meta.created), ['desc']);
    });

    const watchOrdersToDisplay = computed(() =>
      userWatchOrders.value.map((order) => ({
        ...order,
        title: i18n.tc('order.title', undefined, {
          time: dateTimeStr(order.meta.created),
          author: getUserFullName(order.author),
        }),
      })),
    );

    return { isAuthenticated, isOpenWatchOrdersModel, watchOrdersToDisplay };
  },
});
</script>
